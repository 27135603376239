(function () {
  angular
  .module('app')
  .factory('loteriasService', loteriasService);
})();

loteriasService.$inject = ['$http', 'url'];

function loteriasService($http, $url) {

  function getLoterias(){
    return $http.get($url.getApiUrl('/loterias'))
    .then(function(res){
      return res.data;
    })
  }
  function saveLoteria(data){
    return $http.post($url.getApiUrl('/loterias'), data)
    .then(function(res){
      return res.data;
    })
  }

  function updateLoteria(id, data){
    return $http.put($url.getApiUrl('/loterias/'+id), data)
    .then(function(res){
      return res.data;
    })
  }

  function saveDraw(data){
    return $http.post($url.getApiUrl('/sorteos'), data)
    .then(function(res){
      return res.data;
    })
  }

  function updateDraw(id, data){
    return $http.put($url.getApiUrl('/sorteos/'+id), data)
    .then(function(res){
      return res.data;
    })
  }
  function updateOrderDraw(data){
    return $http.post($url.getApiUrl('/sorteos/update_orden'), data)
    .then(function(res){
      return res.data;
    })
  }

  function getDraws(loteriaID){
    return $http.get($url.getApiUrl('/sorteos/'+loteriaID))
    .then(function(res){
      return res.data;
    })

  }
  function getAllDraws(loteriaID){
    return $http.get($url.getApiUrl('/sorteos'))
    .then(function(res){
      return res.data;
    })

  }

  function createSettingsLotery(id, data){
    return $http.post($url.getApiUrl('/loterias/'+id+'/configuracion_loterias'), data)
    .then(function(res){
      return res.data;
    })
  }

  function updateSettingsLotery(id, data, conf_lotery_id){
    return $http.put($url.getApiUrl('/loterias/'+id+'/configuracion_loterias/'+conf_lotery_id), data)
    .then(function(res){
      return res.data;
    })
  }
  function updateSettingsLoteryDefault(data){
    return $http.put($url.getApiUrl('/configuracion_loteria_default/'+0), data)
    .then(function(res){
      return res.data;
    })
  }
  function settingsLoteryDefault(id){
    return $http.get($url.getApiUrl('/configuracion_loteria_default'))
    .then(function(res){
      return res.data;
    })
  }
  function drawsByLotery(id){
    return $http.get($url.getApiUrl('/sorteos/sorteos_lotery'))
    .then(function(res){
      return res.data;
    })
  }
  function getCharities(id){
    return $http.get($url.getApiUrl('/charity/'))
    .then(function(res){
      return res.data;
    })
  }


  return {
    getLoterias: getLoterias,
    saveLoteria: saveLoteria,
    updateLoteria: updateLoteria,
    saveDraw: saveDraw,
    updateDraw: updateDraw,
    updateOrderDraw: updateOrderDraw,
    getDraws: getDraws,
    createSettingsLotery: createSettingsLotery,
    updateSettingsLotery: updateSettingsLotery,
    settingsLoteryDefault: settingsLoteryDefault,
    updateSettingsLoteryDefault: updateSettingsLoteryDefault,
    drawsByLotery: drawsByLotery,
    getAllDraws: getAllDraws,
    getCharities: getCharities
  };
}
