(function(){
  'use strict';

  angular
  .module('app')
  .component('modalDraw', {
    templateUrl: 'app/components/loterias/modalCreateDraw.html',
    controller: ModalCreateDraw,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  })

  ModalCreateDraw.$inject = ['toastr','loteriasService'];

  function ModalCreateDraw(toastr, $loteriasService) {
    var vm = this;
    vm.save = save;
    vm.cancel = cancel;
    vm.send = false;

    vm.$onInit = function(){
      vm.daysOptions = vm.resolve.daysOptions;
      vm.types = vm.resolve.types;
      vm.turno = vm.resolve.turno;
      vm.loteria = vm.resolve.loteria;
      vm.newDraw = {loteria_id: vm.loteria.id};
      vm.tipo_listas = ['A','B','C','Z'];
    }

    function save() {
      vm.send = true;

      _.forEach(vm.newDraw.daysSelected, function(data){
        vm.newDraw[data] = true;
      })

      if(vm.newDraw.tipo != 5 && vm.newDraw.comodin == true){
        vm.newDraw.comodin = false;
      }

      if(vm.newDraw.tipo == 4){
        vm.newDraw.sorteo_quintico.nombre = angular.copy(vm.newDraw.nombre_largo)
        vm.newDraw.sorteo_quintico.nombre_corto = angular.copy(vm.newDraw.nombre)
        vm.newDraw.sorteo_quintico.status = 0;
        vm.newDraw.sorteo_quintico.active = true;
        vm.newDraw.sorteo_quintico.fecha_desde = moment(vm.newDraw.sorteo_quintico.fecha_desde).format('YYYY-MM-DD')
        vm.newDraw.sorteo_quintico.fecha_juega = moment(vm.newDraw.sorteo_quintico.fecha_juega).format('YYYY-MM-DD')
      }

      $loteriasService.saveDraw(vm.newDraw)
      .then(function(res){
        var sorteo = res.data
        sorteo.daysSelected = [];
        toastr.info(res.message);

        if(sorteo.lun){
  			   sorteo.daysSelected.push('lun')
    		}
        if(sorteo.mar){
  			   sorteo.daysSelected.push('mar')
    		}
        if(sorteo.mie){
  			   sorteo.daysSelected.push('mie')
    		}
        if(sorteo.jue){
  			   sorteo.daysSelected.push('jue')
    		}
        if(sorteo.vie){
  			   sorteo.daysSelected.push('vie')
    		}
        if(sorteo.sab){
  			   sorteo.daysSelected.push('sab')
    		}
        if(sorteo.dom){
  			   sorteo.daysSelected.push('dom')
    		}
        vm.close({$value: res.data});
      }, function(err){
        toastr.error(err.data.message);
      })

    }

    function cancel() {
        vm.dismiss();
    }














    vm.inlineOptions = {
      customClass: getDayClass,
      minDate: new Date(),
      showWeeks: true
    };

    vm.dateOptions = {
      dateDisabled: disabled,
      formatYear: 'yy',
      maxDate: null,
      minDate: new Date(),
      startingDay: 1
    };

    // Disable weekend selection
    function disabled(data) {
      var date = data.date,
        mode = data.mode;
      return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
    }

    vm.toggleMin = function() {
      vm.inlineOptions.minDate = vm.inlineOptions.minDate ? null : new Date();
      vm.dateOptions.minDate = vm.inlineOptions.minDate;
    };

    vm.toggleMin();

    vm.open1 = function() {
      vm.popup1.opened = true;
    };

    vm.open2 = function() {
      vm.popup2.opened = true;
    };

    vm.setDate = function(year, month, day) {
      vm.dt = new Date(year, month, day);
    };

    vm.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
    vm.format = vm.formats[0];
    vm.altInputFormats = ['M!/d!/yyyy'];

    vm.popup1 = {
      opened: false
    };

    vm.popup2 = {
      opened: false
    };

    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    var afterTomorrow = new Date();
    afterTomorrow.setDate(tomorrow.getDate() + 1);
    vm.events = [
      {
        date: tomorrow,
        status: 'full'
      },
      {
        date: afterTomorrow,
        status: 'partially'
      }
    ];

    function getDayClass(data) {
      var date = data.date,
        mode = data.mode;
      if (mode === 'day') {
        var dayToCheck = new Date(date).setHours(0,0,0,0);

        for (var i = 0; i < vm.events.length; i++) {
          var currentDay = new Date(vm.events[i].date).setHours(0,0,0,0);

          if (dayToCheck === currentDay) {
            return vm.events[i].status;
          }
        }
      }

      return '';
    }
  }
})();
