(function(){
  'use strict';

  angular
  .module('app')
  .component('orderDraw', {
    templateUrl: 'app/components/order_draw/order_draw.html',
    controller: OrderDrawController
  })

  OrderDrawController.$inject = ['loteriasService', '$uibModal','toastr','$rootScope','miscelaneos','_','$window'];


  function OrderDrawController($loteriasService,$uibModal,toastr, $rootScope, $miscelaneos, _, $window) {
    var vm = this;
    vm.save = save;
    vm.showDraws = false;
    vm.orderingDraws = [];
    function onInit(){
      $loteriasService.drawsByLotery()
      .then(function(res){
        vm.sorteos = res;
      })

    }
    onInit();
    vm.sortableOptions = {
      stop: function(e, ui) {
        _.map(vm.sorteos, function(item, index){
          item.orden = index +1
          return item;
        })
      }
    }



    function save(sorteo){
      vm.send = true;
      _.map(vm.sorteos, function(item, index){
        item.orden = index +1
        return item;
      })
      $loteriasService.updateOrderDraw({sorteos: vm.sorteos})
      .then(function(res){
        vm.send = false;
        toastr.success(res.message);
      }, function(err){
        vm.send = false;
        toastr.error('Ocurrio un error, consulte con soporte tecnico');
      })
    }

    $rootScope.$on('orderLoteries', function(){
      onInit();
    })

    $rootScope.$emit('webLayout', {data: {title: 'Sorteos',subtitle: 'Orden de los sorteos para la taquilla',previewPage: 'Dashboard', currentPage:'Loteria'}});

  }





})();
