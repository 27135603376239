(function(){
  'use strict';

  angular
  .module('app')
  .component('editStructureComponent', {
    templateUrl: 'app/components/structure/editStructure.html',
    controller: ModalEditStructureController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  })

  ModalEditStructureController.$inject = ['toastr','structureService','$rootScope', 'loteriasService','$window'];

  function ModalEditStructureController(toastr, $structureService, $rootScope, $loteriasService, $window) {
    var vm = this;
    vm.send = false;
    vm.atajos = false;
    vm.save = save;
    vm.cancel = cancel;
    vm.nextStep = nextStep;
    vm.backStep = backStep;
    vm.toggleAtajos = toggleAtajos;
    vm.calculateAmount = calculateAmount;
    vm.calculatePercent = calculatePercent;
    vm.calculatePropina = calculatePropina;
    vm.applyPorcentajes = applyPorcentajes;
    vm.updateTipoProducto = updateTipoProducto;
    vm.calculateAmountTriple = calculateAmountTriple;
    vm.updateCurrentStructure = updateCurrentStructure;
    vm.updateConfigLoteriaEstructura = updateConfigLoteriaEstructura;
    vm.$onInit = function(){
      vm.structure = vm.resolve.structure;
      $loteriasService.getLoterias()
      .then(function(res){
        vm.loteries = res;

        _.map(vm.structure.configuracion_estructura, function(conf){
          var loteria = _.findWhere(vm.loteries, {id: conf.loteria_id})
          if(!loteria.tipo_loteria){
            conf.amount_reference = loteria.configuracion_loteria.propina_premio_mayor;
          }
          conf.amount_reference = 0;
          conf.nombre = loteria.nombre;
          return conf;
        })
        _.forEach(vm.structure.configuracion_limites, function(data){
          var loteria = _.findWhere(vm.loteries, {nombre: data.nombre});
          _.forEach(data.sorteos, function(sorteo){
            if(loteria){
              sorteo.porcentajeReference = parseFloat(_.findWhere(loteria.sorteos, {nombre: sorteo.nombre}).limite_terminal);
              sorteo.porcentajeReferenceTriple = parseFloat(_.findWhere(loteria.sorteos, {nombre: sorteo.nombre}).limite_triple);
              calculatePercent(sorteo)
              calculateAmountTriple(sorteo)
            }
          })
        })
        _.map(vm.structure.producto_estructura, function(producto){
          console.log(producto)
          producto.nombre = _.findWhere(vm.structure.tipo_producto, {id: producto.tipo_sorteo_id}).nombre
          return producto;
        })
      })
      vm.type_structures = vm.resolve.type_structures;

    }
    vm.steps = {
      stepOne: true,
      secondStep: false,
      thirdStep: false,
      fourStep: false
    }

    function nextStep(step){

      if(vm.steps.stepOne){
        vm.steps.stepOne = false;
        vm.steps.secondStep = true;
        return;
      }
      if(vm.steps.secondStep){
        vm.steps.secondStep = false;
        vm.steps.thirdStep = true;
        return;
      }
      if(vm.steps.thirdStep){
        vm.steps.thirdStep = false;
        vm.steps.fourStep = true;
        return;
      }

      if(vm.steps.fourStep){
        vm.steps.thirdStep = false;
        vm.steps.fourStep = true;
        return;
      }

    }

    function backStep(step){

      if(vm.steps.secondStep){
        vm.steps.stepOne = true;
        vm.steps.secondStep = false;
        return;
      }

      if(vm.steps.thirdStep){
        vm.steps.secondStep = true;
        vm.steps.thirdStep = false;
        return;
      }

      if(vm.steps.fourStep){
        vm.steps.thirdStep = true;
        vm.steps.fourStep = false;
        return;
      }

    }

    function save() {
      vm.send = true;
      $structureService.updateStructure(vm.structure.id,vm.structure)
      .then(function(res){
        vm.send = false;
        toastr.info('Estructura modificada con exito');
        $rootScope.$emit('structures',{});

      });


    }

    function updateCurrentStructure(tap, data){
      vm.send = true;
      switch (tap) {
        case 'firsTap':
          $structureService.updateStructure(vm.structure.id, vm.structure)
          .then(function(res){
            toastr.info(res.message);
            vm.send = false;
          })
          break;
        case 'secondTap':
        var data = _.forEach(vm.structure.configuracion_estructura, function(setting){delete setting.nombre;delete setting.amount_reference;});
          $structureService.updateStructureConfiguration({id: vm.structure.id, configuracion_estructura: data})
          .then(function(res){
            toastr.info(res.message);
            vm.send = false;
          })

          break;
        case 'thirdTap1':

        $structureService.updateUser(data.id, data)
        .then(function(res){
          toastr.info(res.message);
          vm.send = false;
        })

          break;
        case 'thirdTap2':

        $structureService.updateConfigPremio({config_premio: vm.structure.config_premio, estructura_id: vm.structure.id})
        .then(function(res){
          toastr.info(res.message);
          vm.send = false;
        })

          break;

          case 'fourTap':

          var limites = [];

          _.forEach(vm.structure.configuracion_limites, function(loteria){
            _.forEach(loteria.sorteos, function(sorteo){


              limites.push({
                sorteo_id: sorteo.id,
                porcentaje: sorteo.porcentaje,
                limite: sorteo.limite,
                estructura_id: vm.structure.id,
                limite_triple: sorteo.limite_triple,
                porcentaje_triple: sorteo.porcentaje_triple,
              })
            })
          })


          $structureService.updateLimiteStructure(limites)
          .then(function(res){
            toastr.info(res.message);
            vm.send = false;
          }, function(res){
            vm.send = false;
          })


          break;
        default:

      }

    }

    function updateConfigLoteriaEstructura(config){
      vm.send = true;
      $structureService.updateConfigLoteriaEstructura(vm.structure.id, {config_loteria_estructura: config})
      .then(function(res){
        toastr.info(res.message);
        vm.send = false;
      }, function(res){
        vm.send = false;
      })
    }

    function updateTipoProducto(structure){
      vm.send = true;
      $structureService.updateTipoProducto(structure.id, {productos_estructura: structure.producto_estructura})
      .then(function(res){
        vm.send = false;
        $window.swal(res.message,'','success');
      })
    }

    function applyPorcentajes(currenLotery){
      if(vm.globalPercentTriple){
        _.map(currenLotery.sorteos, function(sorteo){
          sorteo.porcentaje_triple = angular.copy(vm.globalPercentTriple)
          calculateAmountTriple(sorteo)
          return sorteo;
        })
      }
      if(vm.globalPercentTerminal){
        _.map(currenLotery.sorteos, function(sorteo){
          sorteo.porcentaje = angular.copy(vm.globalPercentTerminal)
          calculateAmount(sorteo)
          return sorteo;
        })
      }
      vm.globalPercentTriple = undefined;
      vm.globalPercentTerminal = undefined;
    }

    function toggleAtajos(){
      vm.atajos = !vm.atajos;
    }

    function calculatePercent(data){
      data.porcentaje = data.limite / data.porcentajeReference * 100;
      setAmountPretty(data)
    }

    function calculateAmount(data){
      data.limite = data.porcentajeReference * data.porcentaje / 100;
      setAmountPretty(data)
    }

    function setAmountPretty(data){
      data.pretty_amount = accounting.formatMoney(data.limite, 'BsS. ','2','.',',');
    }

    function calculatePropina(conf){
      var monto = conf.comision_premio_mayor * conf.amount_reference / 100;
      conf.monto_participacion = accounting.formatMoney(monto, '','2','.',',');
    }

    function calculateAmountTriple(data){
      data.limite_triple = data.porcentajeReferenceTriple * data.porcentaje_triple / 100;
      setAmountPrettyTriple(data)
    }

    function setAmountPrettyTriple(data){
      data.pretty_amount_triple = accounting.formatMoney(data.limite_triple, 'BsS. ','2','.',',');
    }

    function cancel() {
        vm.dismiss();
    }
  }
})();
