(function(){
  'use strict';

  angular
  .module('app')
  .component('loterias', {
    templateUrl: 'app/components/loterias/loterias.html',
    controller: LoteriasController
  })

  LoteriasController.$inject = ['loteriasService', '$uibModal','toastr','$rootScope','miscelaneos','_','$window'];


  function LoteriasController($loteriasService,$uibModal,toastr, $rootScope, $miscelaneos, _, $window) {
    var vm = this;
    vm.settingsLoteryDefault = settingsLoteryDefault;
    vm.settingsLotery = settingsLotery;
    vm.createLoterias = createLoterias;
    vm.cancelDraws = cancelDraws;
    vm.editLoteria = editLoteria;
    vm.addSorteos = addSorteos;
    vm.createDraw = createDraw;
    vm.duplicate = duplicate;
    vm.saveAll = saveAll;
    vm.saveOne = saveOne;
    vm.showDraws = false;
    vm.currentLoteria = {};
    vm.types = [];
    vm.newDraw = {};
    vm.turno = [{name: 'manana',turno: 'M'},{name: 'tarde',turno: 'T'},{name: 'noche',turno: 'N'}];
    vm.daysSelected = [];
    vm.daysOptions = ["lun","mar","mie","jue","vie","sab","dom"];
    vm.tipo_listas = ['A','B','C','Z'];


    vm.$onInit = function(){
      $loteriasService.getLoterias()
      .then(function(res){
        vm.loterias = res;
        _.forEach(vm.loterias, function(loteria){
          if(loteria.sorteos.length > 0){
            _.map(loteria.sorteos, function(sorteo){
              sorteo.daysSelected = [];
              if(sorteo.lun){
        			   sorteo.daysSelected.push('lun')
          		}
              if(sorteo.mar){
        			   sorteo.daysSelected.push('mar')
          		}
              if(sorteo.mie){
        			   sorteo.daysSelected.push('mie')
          		}
              if(sorteo.jue){
        			   sorteo.daysSelected.push('jue')
          		}
              if(sorteo.vie){
        			   sorteo.daysSelected.push('vie')
          		}
              if(sorteo.sab){
        			   sorteo.daysSelected.push('sab')
          		}
              if(sorteo.dom){
        			   sorteo.daysSelected.push('dom')
          		}
              return sorteo;
          	})
          }
        })
      })

      $miscelaneos.getTypes()
      .then(function(res){
        vm.types = res;
      })

      $loteriasService.settingsLoteryDefault()
      .then(function(res){
        vm.settings = res;
      })

      $loteriasService.getCharities()
      .then(function(res){
        vm.charities = res;
      })
    }

    function createLoterias(plays){
      var modal = $uibModal.open({
        animation: true,
        component: 'createLoteriasComponent',
        windowClass: 'createLoteriasComponent',
        size: 'md',
        resolve: {
          charities: function(){
            return vm.charities;
          }
        }
      });

      modal.result.then(function(res){
        toastr.success('Loteria creada con exito');
        vm.loterias.push(res)
      }, function(err){
        toastr.error('Accion cancelada');
      });
    }

    function createDraw(){
      var modal = $uibModal.open({
        animation: true,
        component: 'modalDraw',
        windowClass: 'modalDraw',
        size: 'lg',
        resolve:{
          daysOptions: function(){
            return angular.copy(vm.daysOptions)
          },
          types: function(){
            return angular.copy(vm.types)
          },
          turno: function(){
            return angular.copy(vm.turno)
          },
          loteria: function(){
            return angular.copy(vm.currentLoteria)
          }
        }
      });

      modal.result.then(function(res){
        vm.currentLoteria.sorteos.push(res);
      }, function(err){
        toastr.error('Accion Cancelada');
      });
    }


    function editLoteria(loteria){
      var modal = $uibModal.open({
        animation: true,
        component: 'editLoteriasComponent',
        windowClass: 'createLoteriasComponent',
        size: 'md',
        resolve: {
          lotery: function() {
            return angular.copy(loteria);
          },
          charities: function(){
            return vm.charities;
          }
        }
      });

      modal.result.then(function(res){
        toastr.success('Loteria actualizada con exito');
      }, function(err){
        toastr.error('Accion cancelada');
      });
    }

    function settingsLotery(loteria){
      var modal = $uibModal.open({
        animation: true,
        component: 'settingsLoteryComponent',
        size: 'md',
        resolve: {
          lotery: function() {
            return angular.copy(loteria);
          }
        }
      });

      modal.result.then(function(res){
        toastr.success('Loteria: '+res.nombre+' actualizada con exito');
      }, function(err){
        toastr.error('Accion cancelada');
      });
    }

    function settingsLoteryDefault(){
      var modal = $uibModal.open({
        animation: true,
        component: 'settingsLoteryDefaultComponent',
        size: 'md',
        resolve: {
          lotery: function() {
            return angular.copy(vm.settings);
          }
        }
      });

      modal.result.then(function(res){
      }, function(err){
        toastr.error('Accion cancelada');
      });
    }



    function addSorteos(loteria) {
      vm.showDraws = true;
      vm.currentLoteria = angular.copy(loteria);
      _.map(vm.currentLoteria.sorteos, function(sorteo){
        sorteo.daysOptions = ["lun","mar","mie","jue","vie","sab","dom"];
        return sorteo;
      })
      $rootScope.$emit('webLayout', {data: {title: 'Sorteos',subtitle: 'Administracion de sorteos',previewPage: 'Loteria', currentPage:'Sorteos'}});
    }

    function cancelDraws(){
      vm.currentLoteria = {};
      vm.showDraws = false;
      $rootScope.$emit('webLayout', {data: {title: 'Loterias',subtitle: 'Administracion de loterias',previewPage: 'Dashboard', currentPage:'Loteria'}});
    }

    function duplicate(sorteo){
      var sorteo = angular.copy(sorteo);
      $window.swal.setDefaults({
        input: 'text',
        confirmButtonText: 'Siguinte &rarr;',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
        progressSteps: ['1', '2','3','4']
      })

      var steps = [
        {
          title: 'Escriba el nombre',
          text: 'con el que se dupicara el sorteo'
        },
        {
          title: 'Escriba el nombre largo',
          text: 'con el que se dupicara el sorteo'
        },
        {
          title: 'Escriba la hora de cierre',
          text: 'Lunes a Sabado'
        },
        {
          title: 'Escriba la hora de cierre',
          text: 'Domingos'
        },
      ]

      $window.swal.queue(steps).then(function(result){
        $window.swal.resetDefaults()
        sorteo.nombre = result.value[0];
        sorteo.nombre_largo = result.value[1];
        sorteo.horac_ls = result.value[2];
        sorteo.horac_d = result.value[3];
        sorteo.loteria_id = vm.currentLoteria.id;
        sorteo.origin_id = sorteo.id;

        $loteriasService.saveDraw(sorteo)
        .then(function(res){
          toastr.info(res.message);
          var nSorteo = res.data;
          nSorteo.daysSelected = [];
          toastr.info(res.message);

          if(nSorteo.lun){
             nSorteo.daysSelected.push('lun')
          }
          if(nSorteo.mar){
             nSorteo.daysSelected.push('mar')
          }
          if(nSorteo.mie){
             nSorteo.daysSelected.push('mie')
          }
          if(nSorteo.jue){
             nSorteo.daysSelected.push('jue')
          }
          if(nSorteo.vie){
             nSorteo.daysSelected.push('vie')
          }
          if(nSorteo.sab){
             nSorteo.daysSelected.push('sab')
          }
          if(nSorteo.dom){
             nSorteo.daysSelected.push('dom')
          }

          vm.currentLoteria.sorteos.push(nSorteo);
        })
      })
    }

    function saveAll(){
      var currentloteria = angular.copy(vm.currentLoteria)

      _.forEach(currentloteria.sorteos, function(sorteo){
        sorteo.lun = false;sorteo.mar = false;sorteo.mie = false;sorteo.jue = false;sorteo.vie = false;sorteo.sab = false;sorteo.dom = false;
        _.forEach(sorteo.daysSelected, function(e){
          sorteo[e] = true;
        })
        delete sorteo.daysOptions;
        delete sorteo.daysSelected;
      })

      $loteriasService.updateDraw(currentloteria.id, {sorteos: currentloteria.sorteos})
      .then(function(res){
        toastr.success('Sorteos guardados con exito');
      })
    }

    function saveOne(sorteo){
      var Nsorteo = angular.copy(sorteo);
      Nsorteo.lun = false;
      Nsorteo.mar = false;
      Nsorteo.mie = false;
      Nsorteo.jue = false;
      Nsorteo.vie = false;
      Nsorteo.sab = false;
      Nsorteo.dom = false;

      _.forEach(Nsorteo.daysSelected, function(e){
        Nsorteo[e] = true;
      })
      delete Nsorteo.daysOptions;
      delete Nsorteo.daysSelected;

      $loteriasService.updateDraw(vm.currentLoteria.id, {sorteos: [Nsorteo]})
      .then(function(res){
        toastr.success('Sorteo editado con exito');
      })


    }

    $rootScope.$on('loterias', function(){
      vm.$onInit();
    })

    $rootScope.$emit('webLayout', {data: {title: 'Loterias',subtitle: 'Administracion de loterias',previewPage: 'Dashboard', currentPage:'Loteria'}});


  }





})();
