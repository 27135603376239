angular
.module('app')
.config(routesConfig);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider, $locationProvider,$httpProvider) {
  $httpProvider.interceptors.push(['$q', 'user', '$rootScope','$state','$window', function ($q, user, $rootScope,$state, $window) {
    return {
      request: function(config) {
        if(config.url.includes('upload')){
          config.headers['content-type'] = undefined;
        }else{
          config.headers['content-type'] = 'application/json';
        }
        if(user.getCurrentUser()){
          config.headers['token'] = user.getCurrentUser().token;
        }
        return config;
      },
      responseError: function(rejection) {
        if (rejection.status == 401) {
          $rootScope.$broadcast('UNAUTHORIZED');
        }

        return $q.reject(rejection);
      }
    };
  }]);
  $locationProvider.html5Mode(true).hashPrefix('!');
  $urlRouterProvider.otherwise('/');

  $stateProvider
  .state('webLayout', {
    abstract: true,
    component: 'webLayout'
  })
  .state('loginLayout', {
    abstract: true,
    component: 'loginLayout'
  })
  .state('auth', {
    parent: 'loginLayout',
    url: '/login',
    component: 'auth'
  })
  .state('home', {
    parent: 'webLayout',
    url: '/',
    component: 'home',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            if(user.getCurrentUser().tipo == 3){
              window.location = '/reporte-de-numeros';
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  }).state('loterias', {
    parent: 'webLayout',
    url: '/loterias',
    component: 'loterias',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permisos.loterias.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('orderDraw', {
    parent: 'webLayout',
    url: '/ordenar-sorteos',
    component: 'orderDraw'
  })
  .state('structure', {
    parent: 'webLayout',
    url: '/estructuras',
    component: 'structure',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permisos.estructura.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  }).state('integrator', {
    parent: 'webLayout',
    url: '/integradores',
    component: 'integrator',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permisos.integradores.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  }).state('award', {
    parent: 'webLayout',
    url: '/configuracion-premiacion',
    component: 'award',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permisos.premiacion.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  }).state('raffle', {
    parent: 'webLayout',
    url: '/rifas-rifcar',
    component: 'raffle',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permisos.rifa_rifcar.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('hipodromos', {
    parent: 'webLayout',
    url: '/hipodromos',
    component: 'hipodromos',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            // if(!user.getCurrentUser().permisos.rifa_rifcar.active){
            //   $state.go('home');
            // }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('allPollas', {
    parent: 'webLayout',
    url: '/hipodromos/:id/pollas',
    component: 'allPollas',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            // if(!user.getCurrentUser().permisos.rifa_rifcar.active){
            //   $state.go('home');
            // }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('races', {
    parent: 'webLayout',
    url: '/hipodromos/:id/validas',
    component: 'races',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            // if(!user.getCurrentUser().permisos.rifa_rifcar.active){
            //   $state.go('home');
            // }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('pollaPrice', {
    parent: 'webLayout',
    url: '/pollas-precio',
    component: 'pollaPrice',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            // if(!user.getCurrentUser().permisos.rifa_rifcar.active){
            //   $state.go('home');
            // }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('pollaAward', {
    parent: 'webLayout',
    url: '/premiacion-la-polla',
    component: 'pollaAward',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permisos.award_polla.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('currency', {
    parent: 'webLayout',
    url: '/monedas',
    component: 'currency',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('permission', {
    parent: 'webLayout',
    url: '/permisos-de-usuario',
    component: 'permission',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permisos.permisos.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  }).state('users', {
    parent: 'webLayout',
    url: '/usuarios',
    component: 'user',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permisos.usuarios.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  }).state('manualAward', {
    parent: 'webLayout',
    url: '/permiacion-loterias',
    component: 'manualAward',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permisos.premiacion_loterias.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  }).state('promo', {
    parent: 'webLayout',
    url: '/promociones',
    component: 'promotions',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permisos.promotions.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  }).state('reports', {
    parent: 'webLayout',
    url: '/reportes',
    component: 'reports',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permisos.reporte_de_ventas.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ]
    }
  }).state('numberReport', {
    parent: 'webLayout',
    url: '/reporte-de-numeros',
    component: 'numberReport',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permisos.number_report.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  }).state('drawQuintico', {
    parent: 'webLayout',
    url: '/sorteos-quintico',
    component: 'drawQuintico',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permisos.sorteos_quintico.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  }).state('reportAwardTime', {
    parent: 'webLayout',
    url: '/reporte-de-hora-de-premiacion',
    component: 'reportAwardTime',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permisos.report_time_award.active){
              $state.go('home');
            }

            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('reportsLotery', {
    parent: 'webLayout',
    url: '/reporte-por-loterias',
    component: 'reportsLotery',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permisos.report_by_lotery_operadora.active){
              $state.go('home');
            }

            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('preAwardQuintico', {
    parent: 'webLayout',
    url: '/pre-premicion-quintico',
    component: 'preAwardQuintico',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permisos.pre_award_quintico.active){
              $state.go('home');
            }

            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('resultReportQuintico', {
    parent: 'webLayout',
    url: '/reporte-de-premiacion-quintico',
    component: 'reportResultQuintico',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permisos.report_result_award_quintico.active){
              $state.go('home');
            }

            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('comisionsDefault', {
    parent: 'webLayout',
    url: '/comisiones-default-para-centro-de-apuestas',
    component: 'configProducDefault',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permisos.config_default_comisiones_loteria.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  });



}
