(function () {
  angular
  .module('app')
  .factory('structureService', structureService);
})();

structureService.$inject = ['$http', 'url'];

function structureService($http, $url) {

  function getStructures(){
    return $http.get($url.getApiUrl('/estructura'))
    .then(function(res){
      return res.data;
    })
  }
  function saveStructure(data){
    return $http.post($url.getApiUrl('/estructura'), data)
    .then(function(res){
      return res.data;
    })
  }
  function showStructure(id){
    return $http.get($url.getApiUrl('/estructura/'+id))
    .then(function(res){
      return res.data;
    })
  }
  function updateStructure(id, data){
    return $http.put($url.getApiUrl('/estructura/'+id), data)
    .then(function(res){
      return res.data;
    })
  }

  function updateStructureConfiguration(data){
    return $http.post($url.getApiUrl('/estructura/update_configuracion'),data)
    .then(function(res){
      return res.data;
    })
  }

  function updateLimiteStructure(data){
    return $http.post($url.getApiUrl('/estructura/update_limites'), data)
    .then(function(res){
      return res.data;
    })
  }
  function updateUser(id, data){
    return $http.put($url.getApiUrl('/usuarios/'+id), data)
    .then(function(res){
      return res.data;
    })
  }

  function updateConfigPremio(data){
    return $http.post($url.getApiUrl('/estructura/update_config_premio'), data)
    .then(function(res){
      return res.data;
    })
  }

  function updateTipoProducto(id, data){
    return $http.post($url.getApiUrl('/estructura/update_producto_estructura?estructura_id='+id), data)
    .then(function(res){
      return res.data;
    })
  }
  function getComercializadoras(id, data){
    return $http.get($url.getApiUrl('/estructura/get_comercializadoras'))
    .then(function(res){
      return res.data;
    })
  }
  function updateConfigLoteriaEstructura(id, data){
    return $http.post($url.getApiUrl('/estructura/update_config_loteria_estructura?estructura_id='+id), data)
    .then(function(res){
      return res.data;
    })
  }



  return {
    getStructures: getStructures,
    saveStructure: saveStructure,
    updateStructure: updateStructure,
    updateStructureConfiguration: updateStructureConfiguration,
    updateLimiteStructure: updateLimiteStructure,
    updateUser: updateUser,
    updateConfigPremio: updateConfigPremio,
    updateTipoProducto: updateTipoProducto,
    getComercializadoras: getComercializadoras,
    showStructure: showStructure,
    updateConfigLoteriaEstructura: updateConfigLoteriaEstructura

  };
}
