angular
  .module('app', [
    'ui.router',
    'slick',
    'ngSanitize',
    'ngAnimate',
    'ngResource',
    'toastr',
    'ui.bootstrap',
    'angular-ladda',
    'ui.select',
    'rzModule',
    'ngFileUpload',
    'ui.checkbox',
    'ngActionCable',
    'chart.js',
    'ui.sortable'
  ]);
