(function () {
  angular
  .module('app')
  .factory('miscelaneos', miscelaneos);
})();

miscelaneos.$inject = ['$http', 'url','$resource'];

function miscelaneos($http, $url, $resource) {

  var currency = $resource($url.getApiUrl('/currency/:id'), { id: '@_id' }, { 'update': { method: 'PUT' } });

  function getTypes(){
    return $http.get($url.getApiUrl('/miscelaneos/tipo_sorteo'))
    .then(function(res){
      return res.data;
    })
  }
  function getTypeStructures(){
    return $http.get($url.getApiUrl('/miscelaneos/tipo_estructuras'))
    .then(function(res){
      return res.data;
    })
  }

  function getSetting(){
    return $http.get($url.getApiUrl('/miscelaneos/get_structure_config'))
    .then(function(res){
      return res.data;
    })
  }

  function getLimites(){
    return $http.get($url.getApiUrl('/miscelaneos/get_limites'))
    .then(function(res){
      return res.data;
    })
  }

  function getDashboardCounts() {
    return $http.get($url.getApiUrl('/miscelaneos/dashboard_counts'))
    .then(function(res){
      return res.data;
    })
  }
  function getComercializadores() {
    return $http.get($url.getApiUrl('/miscelaneos/get_comercializadores'))
    .then(function(res){
      return res.data;
    })
  }
  function getLoteriaSorteos() {
    return $http.get($url.getApiUrl('/miscelaneos/get_loteria_sorteos'))
    .then(function(res){
      return res.data;
    })
  }

  function availableTimeZones() {
    return $http.get($url.getApiUrl('/miscelaneos/available_time_zones'))
    .then(function(res){
      return res.data;
    })
  }

  function converTimeZone(data) {
    return $http.post($url.getApiUrl('/miscelaneos/convert_time_zone'), data)
    .then(function(res){
      return res.data;
    })
  }

  function getAnimalitos(){
    return [
      {
        number: '01',
        nombre: 'Carnero',
        img: '/images/animales/carnero.png'
      },
      {
        number: '02',
        nombre: 'Toro',
        img: '/images/animales/toro.png'
      },
      {
        number: '03',
        nombre: 'Cienpies',
        img: '/images/animales/ciempies.png'
      },
      {
        number: '04',
        nombre: 'Alacran',
        img: '/images/animales/alacran.png'
      },
      {
        number: '05',
        nombre: 'Leon',
        img: '/images/animales/leon.png'
      },
      {
        number: '06',
        nombre: 'Sapo',
        img: '/images/animales/sapo.png'
      },
      {
        number: '07',
        nombre: 'Loro',
        img: '/images/animales/loro.png'
      },
      {
        number: '08',
        nombre: 'Raton',
        img: '/images/animales/raton.png'
      },
      {
        number: '09',
        nombre: 'Aguila',
        img: '/images/animales/aguila.png'
      },
      {
        number: '10',
        nombre: 'Tigre',
        img: '/images/animales/tigre.png'
      },
      {
        number: '11',
        nombre: 'Gato',
        img: '/images/animales/gato.png'
      },
      {
        number: '12',
        nombre: 'Caballo',
        img: '/images/animales/caballo.png'
      },
      {
        number: '13',
        nombre: 'Mono',
        img: '/images/animales/mono.png'
      },
      {
        number: '14',
        nombre: 'Paloma',
        img: '/images/animales/paloma.png'
      },
      {
        number: '15',
        nombre: 'Zorro',
        img: '/images/animales/zorro.png'
      },
      {
        number: '16',
        nombre: 'Oso',
        img: '/images/animales/oso.png'
      },
      {
        number: '17',
        nombre: 'Pavo',
        img: '/images/animales/pavo.png'
      },
      {
        number: '18',
        nombre: 'Burro',
        img: '/images/animales/burro.png'
      },
      {
        number: '19',
        nombre: 'Cabra',
        img: '/images/animales/cabra.png'
      },
      {
        number: '20',
        nombre: 'Cochino',
        img: '/images/animales/cochino.png'
      },
      {
        number: '21',
        nombre: 'Gallo',
        img: '/images/animales/gallo.png'
      },
      {
        number: '22',
        nombre: 'Camello',
        img: '/images/animales/camello.png'
      },
      {
        number: '23',
        nombre: 'Cebra',
        img: '/images/animales/cebra.png'
      },
      {
        number: '24',
        nombre: 'Iguana',
        img: '/images/animales/iguana.png'
      },
      {
        number: '25',
        nombre: 'Gallina',
        img: '/images/animales/gallina.png'
      },
      {
        number: '26',
        nombre: 'Vaca',
        img: '/images/animales/vaca.png'
      },
      {
        number: '27',
        nombre: 'Perro',
        img: '/images/animales/perro.png'
      },
      {
        number: '28',
        nombre: 'Zamuro',
        img: '/images/animales/zamuro.png'
      },
      {
        number: '29',
        nombre: 'Elefante',
        img: '/images/animales/elefante.png'
      },
      {
        number: '30',
        nombre: 'Caiman',
        img: '/images/animales/caiman.png'
      },
      {
        number: '31',
        nombre: 'Lapa',
        img: '/images/animales/lapa.png'
      },
      {
        number: '32',
        nombre: 'Ardilla',
        img: '/images/animales/ardilla.png'
      },
      {
        number: '33',
        nombre: 'Pescado',
        img: '/images/animales/pescado.png'
      },
      {
        number: '34',
        nombre: 'Venado',
        img: '/images/animales/venado.png'
      },
      {
        number: '35',
        nombre: 'Jirafa',
        img: '/images/animales/jirafa.png'
      },
      {
        number: '36',
        nombre: 'Conejo',
        img: '/images/animales/conejo.png'
      },
      {
        number: '37',
        nombre: 'Culebra',
        img: '/images/animales/culebra.png'
      },
      {
        number: '38',
        nombre: 'Tortuga',
        img: '/images/animales/tortuga.png'
      }
    ]
  }

  function getLigadito(){
    return [
      {
        number: '1',
        nombre: 'Pez',
        img: '/images/ligaditos/pez.png'
      },
      {
        number: '2',
        nombre: 'Trompo',
        img: '/images/ligaditos/trompo.png'
      },
      {
        number: '3',
        nombre: 'Estrella',
        img: '/images/ligaditos/estrella.png'
      },
      {
        number: '4',
        nombre: 'Ancla',
        img: '/images/ligaditos/ancla.png'
      },
      {
        number: '5',
        nombre: 'Copa',
        img: '/images/ligaditos/copa.png'
      },
      {
        number: '6',
        nombre: 'Escalera',
        img: '/images/ligaditos/escalera.png'
      }
    ]
  }

  function getSings(){
    return [
      {
        number: '01',
        signo: 'Aries',
        img: '/images/signos/1.png'
      },{
        number: '02',
        signo: 'Tauro',
        img: '/images/signos/2.png'
      },{
        number: '03',
        signo: 'Geminis',
        img: '/images/signos/3.png'
      },{
        number: '04',
        signo: 'Cancer',
        img: '/images/signos/4.png'
      },{
        number: '05',
        signo: 'Leo',
        img: '/images/signos/5.png'
      },{
        number: '06',
        signo: 'Virgo',
        img: '/images/signos/6.png'
      },{
        number: '07',
        signo: 'Libra',
        img: '/images/signos/7.png'
      },{
        number: '08',
        signo: 'Escorpio',
        img: '/images/signos/8.png'
      },{
        number: '09',
        signo: 'Sagitario',
        img: '/images/signos/9.png'
      },{
        number: '10',
        signo: 'Capricornio',
        img: '/images/signos/10.png'
      },{
        number: '11',
        signo: 'Acuario',
        img: '/images/signos/11.png'
      },{
        number: '12',
        signo: 'Piscis',
        img: '/images/signos/12.png'
      }
    ]
  }

  return {
    getTypes: getTypes,
    getTypeStructures: getTypeStructures,
    getSetting: getSetting,
    getLimites: getLimites,
    getAnimalitos: getAnimalitos,
    getDashboardCounts: getDashboardCounts,
    getLigadito: getLigadito,
    getSings: getSings,
    getComercializadores: getComercializadores,
    getLoteriaSorteos: getLoteriaSorteos,
    currency: currency,
    availableTimeZones: availableTimeZones,
    converTimeZone: converTimeZone
  };
}
