
(function(){
  'use strict';

  angular
  .module('app')
  .component('editLoteriasComponent', {
    templateUrl: 'app/components/loterias/modalCreate.html',
    controller: ModalEditloteriaController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  })

  ModalEditloteriaController.$inject = ['toastr','loteriasService','$rootScope'];

  function ModalEditloteriaController(toastr, $loteriasService, $rootScope) {
    var vm = this;
    vm.save = save;
    vm.cancel = cancel;
    vm.send = false;
    setTimeout(function(){
    },0);
    // vm.draws = vm.resolve.draws;
    
    vm.$onInit = function(){
      vm.lotery = vm.resolve.lotery
      vm.charities = vm.resolve.charities;
      vm.charitySelected = _.find(vm.charities, {id: vm.lotery.charity})
    }

    function save() {
      vm.send = true;
      vm.lotery._method = 'PUT';
      $loteriasService.updateLoteria(vm.lotery.id,vm.lotery)
      .then(function(res){
        vm.send = false;
        console.log(res)
        $rootScope.$emit('loterias',{});
        vm.close({$value: res.data});
      }, function(err){
        vm.send = false;
        console.log(err)
        // toastr.error(err.data.message);
      })
    }

    function cancel() {
        vm.dismiss();
    }
  }
})();
