(function(){
  'use strict';

  angular
  .module('app')
  .component('structure', {
    templateUrl: 'app/components/structure/structure.html',
    controller: StructureController
  })

  StructureController.$inject = ['$uibModal','toastr','$rootScope','miscelaneos','_','$window','structureService','loteriasService'];


  function StructureController($uibModal,toastr, $rootScope, $miscelaneos, _, $window, $structureService,$loteriasService) {
    var vm = this;
    vm.send = false;
    vm.loadingData = true;
    vm.createStructure = createStructure;
    vm.editStructure = editStructure;
    vm.structures = [];
    vm.structure = {};
    vm.type_structures = [];
    vm.lotteries = [];


    vm.$onInit = function(){
      $structureService.getStructures()
      .then(function(res){
        vm.structures = res
        _.forEach(vm.structures, function(structure){
          structure.limites = _.groupBy(structure.configuracion_limites, structure.sorteo_id)
        })
        vm.loadingData = false;
        


      })


      $miscelaneos.getTypeStructures()
      .then(function(res){
        vm.type_structures = res;
      })

      $miscelaneos.getSetting()
      .then(function(res){
        vm.structure.configuracion_estructura = res;
        $loteriasService.getLoterias()
        .then(function(res){
          var loterias = res;
          _.map(vm.structure.configuracion_estructura, function(conf_struct){
            var loteria = _.findWhere(loterias, {id: conf_struct.loteria_id});
            if(loteria && loteria.tipo_loteria == false){
              console.log(loteria)
              conf_struct.amount_reference = loteria.configuracion_loteria.propina_premio_mayor || 0;
            }
            return conf_struct;
          })
        })
      })

      $miscelaneos.getLimites()
      .then(function(res){
        vm.structure.configuracion_limites = res;
      })


    }


    function createStructure() {
      var modal = $uibModal.open({
        animation: true,
        component: 'createStructureComponent',
        windowClass: 'createEstructure',
        size: 'lg',
        resolve: {
          type_structures: function(){
            return angular.copy(vm.type_structures)
          },
          structure: function() {
            return angular.copy(vm.structure)
          },
          structures: function(){
            return angular.copy(vm.structures)
          }
        }
      });

      modal.result.then(function(res){
        vm.structures.push(res)
      }, function(err){
        toastr.error('Accion cancelada');
      });

    }

    function editStructure(structure) {
      vm.loadingData = true;
      var modal = $uibModal.open({
        animation: true,
        component: 'editStructureComponent',
        windowClass: 'editStructureComponent',
        size: 'lg',
        resolve: {
          type_structures: function(){
            return angular.copy(vm.type_structures)
          },
          structure: function(){
            return $structureService.showStructure(structure.id)
            .then(function(res){
              vm.loadingData = false;
              return angular.copy(res);
            })
          },
          structures: function(){
            return angular.copy(vm.structures)
          }
        }
      });

      modal.result.then(function(res){
        // console.log('mardito rails de mierda')
      }, function(err){
        toastr.error('Accion cancelada');
      });

    }

    $rootScope.$emit('webLayout', {data: {title: 'Estructuras',subtitle: 'Administracion de estructuras',previewPage: 'Dashboard', currentPage:'Estructura'}});

    $rootScope.$on('structures', function(){
      vm.$onInit();
    })

  }

})();
